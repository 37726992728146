

import firebase from "gatsby-plugin-firebase"

function getFire ()
{
  let fireApp

  if (typeof window !== "undefined") 
  {

    // Your web app's Firebase configuration
    var firebaseConfig = {
      apiKey: `${process.env.GATSBY_apiKey}`,
      authDomain: `${process.env.GATSBY_authDomain}`,
      projectId: `${process.env.GATSBY_projectId}`,
      storageBucket: `${process.env.GATSBY_storageBucket }`,
      messagingSenderId: `${process.env.GATSBY_messagingSenderId}`,
      appId: `${process.env.GATSBY_appId}`,
      measurementId: `${process.env.GATSBY_measurementId}`
    };


    // Initialize Firebase
    //fireApp = (firebase.initializeApp(firebaseConfig)).auth();
    fireApp = firebase.auth()
    
    //console.log("Process env is",process.env.GATSBY_authDomain)

  }
  else
  {
    fireApp = 
    {
      currentUser: {
        updateEmail : function(email) { return "ligma"},
        updatePassword : function(password) { return "ligma"},
        email : 0,
      },
      createUserWithEmailAndPassword : function(email, password) { return "ligma"},
      signInWithEmailAndPassword: function (email, password){ return "ligma"},
      signOut : function(){ return "ligma"},
      sendPasswordResetEmail : function(email){ return "ligma"},
      verifyPasswordResetCode : function(actionCode) { return "ligma"},
      confirmPasswordReset : function(actionCode, newPassword) { return "ligma"},
      signInWithEmailAndPassword : function(accountEmail, newPassword){ return "ligma"},
      onAuthStateChanged : function(user){ return "ligma"},
    }
  }
  return fireApp
}

export const auth = getFire()


function getFireAnalytics ()
{
  let fireLit

  if (typeof window !== "undefined") 
  {
    fireLit = firebase.analytics();
  }
  else
  {
    fireLit = {test:"ligma"}
  }
  return fireLit
}

export const fireAnalytics = getFireAnalytics()
//console.log("firebase analytics is",fireAnalytics)

/*
var firebaseConfig = {
  apiKey: `${process.env.GATSBY_apiKey}`,
  authDomain: `${process.env.GATSBY_authDomain}`,
  projectId: `${process.env.GATSBY_projectId}`,
  storageBucket: `${process.env.GATSBY_storageBucket }`,
  messagingSenderId: `${process.env.GATSBY_messagingSenderId}`,
  appId: `${process.env.GATSBY_appId}`,
  measurementId: `${process.env.GATSBY_measurementId}`
};



// Initialize Firebase
const fireApp = firebase.initializeApp(firebaseConfig);
//console.log("Process env is",process.env.GATSBY_authDomain)
export const auth = fireApp.auth()
*/

export default firebase
