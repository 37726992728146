import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import './styles.css'

export default () => {
  const { author } = useStaticQuery(query).site.siteMetadata
  return (
    <div className="footer text-muted text-center">
      <br/><br/>
      <b>
        <a href={`https://www.twitter.com/appraisir`} className="linkColor" target="_blank" style={{color:'#6c757d'}}>Twitter</a> &nbsp;&nbsp;
        <a href={`https://www.instagram.com/appraisir/`} className="linkColor" target="_blank" style={{color:'#6c757d'}}>Instagram</a> &nbsp;&nbsp;
        <a href={`https://www.pinterest.com/appraisir/`} className="linkColor" target="_blank" style={{color:'#6c757d'}}>Pinterest</a>
      </b>
      <br/><br/>
      <span className="m-auto">
        <b>{author}</b> &copy; {new Date().getFullYear()}.
      </span>
      
      <br/><br/>
    </div>
  )
}
const query = graphql`
  query Author {
    site {
      siteMetadata {
        author
      }
    }
  }
`
