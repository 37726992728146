import React, {useContext } from "react"
import { Container } from "react-bootstrap"
import Header from "./Header"
import Footer from "./Footer"
import ThemeContext from "../utils/theme"

export default ({ children }) => 
{
  const { dark, setModeDark, toggleDark, firstTime, setFirst } = useContext(ThemeContext)
  if (firstTime)
  {
    setTimeout(() => 
    {  
      toggleDark()
    }, 120);
    setTimeout(() => 
    {  
      toggleDark()
    }, 77);
    setFirst()
  }
  return(
    <Container fluid className={`px-0 app-container`}>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
      />
      <Header />
      <Container fluid className="pt-5 mt-5 text-center" 
        style={{minHeight:'78vh'/*backgroundColor:` ${dark ? '#111' : '#fff' } `*/ 
        /*backgroundImage: `${dark ? 'radial-gradient(circle at 170% -155%, #fdcc63, #fdcc63 50%, #111 64%, #000 85%)' : 'radial-gradient(circle at 170% -155%, #fdcc63, #fdcc63 50%, #b3e0f2 64%, #b3e0f2 85%)' }`,*/ 
        /*backgroundImage:`url(${dark ? '../../icons/backgroudNight.png' : '../../icons/backgroundDay.png' } )`*/}}>
        {children}
      </Container>
      <Footer />
    </Container>
  )
}