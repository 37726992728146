import React, { useState, useEffect } from "react"
import { auth } from "./firebase"
import { GoogleAuthProvider } from "firebase/auth";
import firebase from "gatsby-plugin-firebase"
//const auth = getAuth()

export function printing(props)
{ return `${props} stuff`}


//const [currentUser, setCurrentUser] = useState()
//const [loading, setLoading] = useState(true)

export function FirebaseAuth()
{ 
  //this funtion is never called, not really needed anymore
  const [currUser, setCurrUser] = useState()
  useEffect(() => 
  {
    const unsubscribe = auth.onAuthStateChanged(user => 
    {
      setCurrUser(user)
      //console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
    })
    return unsubscribe
  }, [])
  return currUser
}

export function loggedUser()
{
  return auth.currentUser
}

export function signup(email, password) 
{ return auth.createUserWithEmailAndPassword(email, password)   }

export function login(email, password) 
{ return auth.signInWithEmailAndPassword(email, password)   }

export function loginWithGoogle() 
{
  let provider = new firebase.auth.GoogleAuthProvider();
  return auth.signInWithPopup(provider)   
}


export function logout() 
{ 
  localStorage.removeItem('userData')
  localStorage.removeItem('profileData')
  return auth.signOut()   
}

export function resetPassword(email) 
{ return auth.sendPasswordResetEmail(email)   }

export function updateProfile(name) 
{ return auth.currentUser.updateProfile({"displayName":name})  }

export function updateEmail(email) 
{ return auth.currentUser.updateEmail(email)   }

export function updatePassword(password) 
{ return auth.currentUser.updatePassword(password)   }

export async function verifyResetLink(actionCode) 
{ 
  return await auth.verifyPasswordResetCode(actionCode).then((email) => 
  {
    //console.log("email should be",email)
    return email

  }).catch((error) => 
  {
    // Invalid or expired action code. Ask user to try to reset the password
    // again.
    let ret = {"error":error}
    return ret
  });
}

export async function handleResetPassword(actionCode,accountEmail,newPassword)  
{
    // Save the new password.
    return await auth.confirmPasswordReset(actionCode, newPassword).then( async (resp) => 
    {
      // Password reset has been confirmed and new password updated.

      await auth.signInWithEmailAndPassword(accountEmail, newPassword);
      return true

    }).catch((error) => 
    {
      // Error occurred during confirmation. The code might have expired or the
      // password is too weak.
      let ret = {"error":error}
      return ret
    });

  
}

